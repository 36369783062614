import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { useLocation } from '@reach/router'

import type { IPrismicData } from 'types/lib'
import { getCurrentLanguage } from 'lib/getLocalizedData'

const storiesQuery = graphql`
  query storiesQuery {
    allPrismicStory(sort: { fields: data___order, order: ASC }) {
      edges {
        node {
          _previewable
          lang
          uid
          data {
            title
            image {
              gatsbyImageData
            }
          }
        }
      }
    }
    allPrismicVideosPage {
      edges {
        node {
          _previewable
          lang
          data {
            videos {
              story {
                uid
              }
            }
          }
        }
      }
    }
  }
`

const useStories = () => {
  const staticData = useStaticQuery(storiesQuery)
  const { data }: IPrismicData = useMergePrismicPreviewData(staticData)
  const { pathname } = useLocation()
  const lang = getCurrentLanguage(pathname)

  const videosPage = data.allPrismicVideosPage.edges.map(e => e.node).filter(node => node.lang === lang)
  const withVideo = videosPage[0].data.videos.map(video => video.story.uid)

  const stories = data.allPrismicStory.edges
    .map(e => e.node)
    .filter(node => node.lang === lang)
    .filter(story => withVideo.some(uid => uid === story.uid))

  return {
    stories,
    initialStoryUid: stories.length ? stories[0].uid : '',
  }
}

export default useStories
