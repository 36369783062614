import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'prismic/linkResolver'
import ProgressPage from 'views/ProgressPage'

import { prismicRepo } from '../../prismic-configuration'

export const query = graphql`
  query ProgressQuery($lang: String) {
    prismicProgress(lang: { eq: $lang }) {
      _previewable
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        explanatory_title
        explanatory_tooltip
        explanatory_section {
          section_title {
            text
          }
          text {
            text
          }
        }
        videos_title
        videos_helper
        facts_title
        facts_helper
        votes_title
        votes_cta
        no_votes_cta
        facts_progress_prefix
        stories_progress_prefix
        progress_helper
        progress_helper_tooltip {
          text
        }
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
          dimensions {
            height
            width
          }
          alt
        }
      }
    }
    prismicVideosPage(lang: { eq: $lang }) {
      _previewable
      data {
        videos {
          youtube_embed_id
          title {
            text
          }
          subtitle {
            text
          }
          story {
            uid
          }
        }
      }
      uid
    }
    prismicSolutionsPage(lang: { eq: $lang }) {
      uid
    }
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        body {
          ... on PrismicHomepageDataBodyCategory {
            primary {
              category_title
            }
            items {
              category_fact {
                document {
                  ... on PrismicFact {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicFact(filter: { lang: { eq: $lang } }) {
      totalCount
    }
    prismicSiteSettings(lang: { eq: $lang }) {
      lang
      data {
        dialog_description
      }
    }
  }
`

// @ts-ignore
export default withPrismicPreview(ProgressPage, [
  {
    repositoryName: prismicRepo,
    // @ts-ignore
    linkResolver,
  },
])
