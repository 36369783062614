import React, { useMemo, useState, useEffect } from 'react'
import classNames from 'classnames/bind'

import getLocalizedPath from 'lib/getLocalizedPath'
import useMaximumVotes from 'lib/useMaximumVotes'
import useIsDesktop from 'lib/useIsDesktop'
import useFirebaseContext from 'context/firebase'
import readFromLocalStorage from 'lib/readFromLocalStorage'
import useCopyContext from 'context/copy'
import useTheme from 'lib/useTheme'
import useStories from 'lib/useStories'
import { Row, Column } from 'components/core/Grid'
import AppearAnimation from 'components/core/AppearAnimation'
import Container from 'components/core/Container'
import SEO from 'components/core/SEO'
import Spacer from 'components/core/Spacer'
import { MonumentHeading1 } from 'components/ui/Text'
import { ProgressPopup, ProgressList } from 'components/ui/Progress'
import Popup from 'components/ui/Popup'
import Counter from 'components/ui/Counter'
import { TooltipTrigger } from 'components/ui/Tooltip'
import { VoteButton } from 'components/ui/Buttons'

import { ProgressPageTypes, IStory } from './ProgressPageTypes'
import * as s from './ProgressPage.module.scss'

const cn = classNames.bind(s)

const DELAY_ANIMATION_DURATION = 0.25

const ProgressPage = ({ data }: ProgressPageTypes) => {
  const isDesktop = useIsDesktop()
  const [showExplanation, setShowExplanation] = useState<boolean>(false)
  const [showStorageExplanation, setShowStorageExplanation] = useState<boolean>(false)
  const readFacts = readFromLocalStorage('readFacts')
  const totalFacts = useMemo(() => data?.allPrismicFact?.totalCount || 0, [data])
  const viewedVideos = readFromLocalStorage('viewedVideos')
  const setCopy = useCopyContext(s => s.setCopy)
  useTheme('dark', 'white')

  /* Firebase */
  const max = useMaximumVotes()
  const spent = useFirebaseContext(s => s.spentVotes)
  const earned = useFirebaseContext(s => s.earnedVotes)
  const remaining = useMemo(() => (earned === undefined || spent === undefined ? 0 : earned - spent), [earned, spent])

  const videos = useMemo(() => {
    if (!data?.prismicVideosPage?.data?.videos?.length) return []
    return data.prismicVideosPage.data.videos.map(video => ({
      title: `${video?.subtitle?.text}: ${video?.title?.text}`,
      completed: viewedVideos.find(v => v === video.youtube_embed_id) ? 1 : 0,
      path: `/${data.prismicVideosPage.uid}`,
      storyUid: video?.story?.uid,
    }))
  }, [data, viewedVideos])

  const { stories } = useStories()
  const areStoriesSet = !!videos.filter(v => v.storyUid).length

  const videosInStories = useMemo(() => {
    if (!areStoriesSet) return []
    return stories.map((s: IStory) => {
      const videosInStory = videos.filter(v => v.storyUid === s.uid)
      return {
        videos: videosInStory,
        path: videosInStory[0].path, // Same path for all, use first one
        story: {
          title: s.data.title,
          progress: videosInStory.filter(v => v.completed).length,
          total: videosInStory.length,
          uid: s.uid,
        },
      }
    })
  }, [areStoriesSet, stories, videos])

  const facts = useMemo(() => {
    if (!data?.prismicHomepage?.data?.body?.length) return []

    const sanitized = data.prismicHomepage.data.body.map(c => ({
      title: c.primary.category_title || '',
      facts: c.items
        ?.map(f => {
          const uid = f.category_fact?.document?.uid
          if (!uid) return null
          return { uid, read: !!readFacts.find(i => i === uid) }
        })
        ?.filter(Boolean),
    }))

    const items = sanitized.map(category => {
      const len = category?.facts?.length || 0
      let completed = 0
      if (len > 0 && category?.facts) {
        completed = category.facts.reduce((totalValue: number, fact) => {
          if (fact?.read) totalValue += 1
          return totalValue
        }, 0)
      }

      return { title: category.title, completed, max: len, path: `/${category.title}` }
    })
    return items
  }, [data, readFacts])

  /* UI Labels */
  const {
    page_meta_title,
    page_meta_description,
    page_meta_thumbnail,
    explanatory_title,
    explanatory_tooltip,
    explanatory_section,
    videos_title,
    videos_helper,
    facts_title,
    facts_helper,
    progress_helper,
    progress_helper_tooltip,
    votes_title,
    votes_cta,
    no_votes_cta,
    stories_progress_prefix,
    facts_progress_prefix,
    title,
  } = data?.prismicProgress?.data

  useEffect(() => {
    if (!data?.prismicSiteSettings) return

    setCopy({
      dialog_description: data.prismicSiteSettings.data.dialog_description,
      facts_progress_prefix,
      stories_progress_prefix,
      videos: videos_title,
      facts: facts_title,
    })
  }, [setCopy, facts_progress_prefix, stories_progress_prefix, videos_title, facts_title, data?.prismicSiteSettings])

  if (!data) return null
  const document = data.prismicProgress || {}
  const solutionsUid = data.prismicSolutionsPage?.uid

  return (
    <AppearAnimation>
      <Container className={cn('container')}>
        <SEO title={page_meta_title} description={page_meta_description} thumbnail={page_meta_thumbnail} />
        <div className={cn('upper')}>
          <div className={cn('counterContainer')}>
            <Counter className={cn('counter')} title={votes_title || ''} />
            {max !== earned &&
              solutionsUid &&
              remaining !== 0 && ( // VOTES LEFT
                <VoteButton
                  className={cn('button')}
                  to={getLocalizedPath(solutionsUid, document.lang)}
                  inverted
                  narrow
                  classNameLabel={cn('secondary')}
                >
                  {votes_cta || ''}
                </VoteButton>
              )}
            {max !== earned &&
              remaining === 0 && ( // NO VOTES LEFT
                <VoteButton
                  className={cn('button')}
                  to={getLocalizedPath('', document.lang)}
                  inverted
                  narrow
                  classNameLabel={cn('secondary')}
                >
                  {no_votes_cta || ''}
                </VoteButton>
              )}
            <Spacer initial='14.7vw' hideOnDesktop />
          </div>
          <Row>
            <Column initial={6} medium={4}>
              <MonumentHeading1 scale='monumentH2' className={cn('title')}>
                {title?.text || ''}
              </MonumentHeading1>
              <Spacer initial='5.8vw' medium='3vw' />
              {explanatory_section?.length && (
                <TooltipTrigger
                  onClick={() => setShowExplanation(true)}
                  onEnter={() => setShowExplanation(true)}
                  onLeave={() => setShowExplanation(false)}
                  title={explanatory_tooltip}
                >
                  <ProgressPopup
                    onClose={() => setShowExplanation(false)}
                    open={showExplanation}
                    title={explanatory_title || ''}
                    sections={explanatory_section}
                  />
                </TooltipTrigger>
              )}
            </Column>
            <Column initial={6} medium={2} />
            <Spacer initial='14.7vw' medium='6.1vw' />
          </Row>
        </div>
        <Spacer initial='11.7vw' medium='4.2vw' />
        <div className={cn('storageExplantation')}>
          <TooltipTrigger
            onClick={() => setShowStorageExplanation(true)}
            onEnter={() => setShowStorageExplanation(true)}
            onLeave={() => setShowStorageExplanation(false)}
            title={progress_helper}
          >
            <Popup
              open={showStorageExplanation}
              onClose={() => setShowStorageExplanation(false)}
              left='0'
              translateX='0'
            >
              {progress_helper_tooltip?.text || ''}
            </Popup>
          </TooltipTrigger>
        </div>
        <Spacer initial='11vw' medium='3.28vw' />
        <Row>
          <Column initial={6} medium={3} className={cn('col')}>
            <ProgressList
              animationDelay={DELAY_ANIMATION_DURATION}
              className={cn('inner')}
              title={facts_title || ''}
              caption={facts_helper || ''}
              total={totalFacts}
              progress={readFacts.length}
              list={facts}
              showCount
              type='fact'
            />
            <Spacer initial='15.4vw' hideOnDesktop />
          </Column>
          <Column initial={6} medium={3} className={cn('col')}>
            <Spacer initial='15.4vw' hideOnDesktop />
            <ProgressList
              animationDelay={
                isDesktop ? DELAY_ANIMATION_DURATION * 1.8 : DELAY_ANIMATION_DURATION
              } /* Add extra delay on desktop to create staggering animation */
              className={cn('inner')}
              title={videos_title || ''}
              caption={videos_helper || ''}
              progress={viewedVideos.length}
              total={
                !areStoriesSet ? videos.length : videosInStories.reduce((acc, curr) => acc + curr.videos.length, 0)
              }
              list={!areStoriesSet ? videos : videosInStories}
              type={!areStoriesSet ? 'video' : 'stories'}
            />
          </Column>
        </Row>
        <Spacer initial='39vw' medium='9vw' />
      </Container>
    </AppearAnimation>
  )
}

export default ProgressPage
