import React from 'react'
import classNames from 'classnames/bind'

import Popup from 'components/ui/Popup'
import { MonumentCaption, MonumentNavigation, CircularCaption, CircularBody } from 'components/ui/Text'

import { ProgressPopupTypes } from './ProgressPopupTypes'
import * as s from './ProgressPopup.module.scss'
const cn = classNames.bind(s)

const ProgressPopup = ({ open, onClose, title, sections }: ProgressPopupTypes) => {
  return (
    <Popup containerClassName={cn('popup')} onClose={onClose} open={open} left='0' translateX='0' ariaLabel={title}>
      <div className={cn('inner')}>
        <CircularBody className={cn('popupTitle')}>{title}</CircularBody>
        <div className={cn('popupSections')}>
          {sections.map((i, index) => (
            <section className={cn('popupSection')} key={`explanatory-section-${index}`}>
              <div className={cn('count')} key={`explanatory-count-${index}`}>
                <MonumentNavigation className={cn('number')} as='h2'>
                  {index + 1}
                </MonumentNavigation>
              </div>
              <div>
                <MonumentCaption as='h3' className={cn('sectionTitle')}>
                  {i.section_title?.text || ''}
                </MonumentCaption>
                <CircularCaption weight='book' as='p'>
                  {i.text?.text || ''}
                </CircularCaption>
              </div>
            </section>
          ))}
        </div>
      </div>
    </Popup>
  )
}

export default ProgressPopup
