// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var subtitle = "ProgressList-module--subtitle--3WTa9";
export var list = "ProgressList-module--list--1olW5";
export var item = "ProgressList-module--item--3qsU2";
export var link = "ProgressList-module--link---LI_4";
export var notStarted = "ProgressList-module--notStarted--2Ofcq";
export var storyHeader = "ProgressList-module--storyHeader--3rfRw";
export var storyProgress = "ProgressList-module--storyProgress--2FXBf";
export var storyVideos = "ProgressList-module--storyVideos--fjOYb";