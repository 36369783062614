// extracted by mini-css-extract-plugin
export var colorBlack = "#000";
export var colorGray = "gray";
export var colorDarkGray = "#262626";
export var colorLightGray = "#e5e5e5";
export var colorWhite = "#fff";
export var colorElectricBlue = "#0000fe";
export var colorPastelBlue = "#bbe5fe";
export var colorCandyPink = "#f9709a";
export var colorPastelPink = "#ffcdd2";
export var colorDefaultLime = "#cef564";
export var colorPastelLime = "#eeffbe";
export var colorBlueLagoon = "#9cf0e1";
export var colorPastelLagoon = "#ceffec";
export var colorDefaultLavender = "#8738f6";
export var colorPastelLavender = "#eadcff";
export var colorBrightYellow = "#ffe500";
export var colorDefaultGreen = "#12b968";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var colCount = "6";
export var colWidth = "16.6666666667%";
export var container = "ProgressPage-module--container--2kVsW";
export var title = "ProgressPage-module--title--3I0t-";
export var upper = "ProgressPage-module--upper--3LO_R";
export var counterContainer = "ProgressPage-module--counterContainer--1uhZQ";
export var counter = "ProgressPage-module--counter--xrzaD";
export var button = "ProgressPage-module--button--1rSfr";
export var storageExplantation = "ProgressPage-module--storageExplantation --1bLin";
export var explanation = "ProgressPage-module--explanation--2hraa";
export var tooltip = "ProgressPage-module--tooltip--2BNS1";
export var explanationTitle = "ProgressPage-module--explanationTitle--3Yckr";
export var explanationExtended = "ProgressPage-module--explanationExtended--2ROsM";
export var col = "ProgressPage-module--col--3La_-";
export var inner = "ProgressPage-module--inner--AxOMG";