import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'

import { MonumentProgress } from 'components/ui/Text'
import ViewportEnter from 'components/core/ViewportEnter'
import useCopyContext from 'context/copy'

import { ProgressBarTypes } from './ProgressBarTypes'
import * as s from './ProgressBar.module.scss'
const cn = classNames.bind(s)

const ProgressBar = ({ current, max, animationDelay, type }: ProgressBarTypes) => {
  const [isInView, setIsInView] = useState(false)
  const copy = useCopyContext(s => s.copy)

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('container')}>
        <div className={cn('background')}>
          <motion.div
            initial={{ scaleX: 0 }}
            animate={{ scaleX: isInView ? current / max : 0 }}
            className={cn('progress')}
            transition={{
              duration: 4 * (current / max),
              delay: animationDelay || 0,
              ease: [0.175, 0.885, 0.32, 1.05],
            }}
          />
        </div>
        <MonumentProgress
          className={cn('text')}
          aria-live='polite'
          role='status'
          aria-label={
            type === 'fact'
              ? `${copy.facts_progress_prefix} ${current}/${max} ${copy.facts?.toLowerCase()}`
              : `${copy.stories_progress_prefix} ${current}/${max} ${copy.videos?.toLowerCase()}`
          }
        >{`${current} / ${max}`}</MonumentProgress>
      </div>
    </ViewportEnter>
  )
}

export default ProgressBar
