import React from 'react'
import classNames from 'classnames/bind'

import { MonumentSubtitle, CircularCaption, CircularBody, MonumentProgress, CircularStory } from 'components/ui/Text'
import Spacer from 'components/core/Spacer'
import Clickable from 'components/core/Clickable'
import { ProgressBar } from 'components/ui/Progress'

import { ProgressListTypes } from './ProgressListTypes'
import * as s from './ProgressList.module.scss'
const cn = classNames.bind(s)

const ProgressList = ({
  total,
  progress,
  title,
  caption,
  list,
  showCount,
  className,
  animationDelay,
  type,
}: ProgressListTypes) => (
  <section className={className}>
    <MonumentSubtitle className={cn('subtitle')} as='h2'>
      {title}
    </MonumentSubtitle>
    <Spacer initial='2.67vw' medium='1.2vw' />
    <CircularCaption as='p'>{caption}</CircularCaption>
    <Spacer initial='2.67vw' medium='1.2vw' />
    <ProgressBar current={progress} max={total} animationDelay={animationDelay} type={type} />
    <Spacer initial='10.4vw' medium='3.4vw' />
    <ul className={cn('list')}>
      {type === 'stories'
        ? list.map(item => {
            return (
              <li className={cn('story')} key={item.story?.title}>
                <Clickable className={cn('storyHeader')} to={`${item.path}?uid=${item?.story?.uid}`}>
                  <CircularStory>{item?.story?.title}</CircularStory>
                  <MonumentProgress className={cn('storyProgress')}>
                    {item?.story?.progress} / {item?.story?.total}
                  </MonumentProgress>
                </Clickable>
                <ul className={cn('storyVideos')}>
                  {item?.videos?.map((video, i) => (
                    <li className={cn('item', { notStarted: !video.completed })} key={`${title}-item-${i}`}>
                      <Clickable
                        to={`${video.path}?uid=${item?.story?.uid}`}
                        className={cn('link')}
                        state={{ initialVideo: i }}
                      >
                        <CircularBody>{video.title}</CircularBody>
                      </Clickable>
                    </li>
                  ))}
                </ul>
              </li>
            )
          })
        : list.map((item, index) => (
            <li className={cn('item', { notStarted: !item.completed })} key={`${title}-item-${index}`}>
              <Clickable to={item.path} className={cn('link')} state={{ initialVideo: index }}>
                <CircularBody>{item.title}</CircularBody>
                {showCount && <MonumentProgress>{`${item.completed} / ${item.max}`}</MonumentProgress>}
              </Clickable>
            </li>
          ))}
    </ul>
  </section>
)

export default ProgressList
